import React, { Component } from "react";
import Layout from "../components/layout";
import Container from "../components/container";
import styles from "./kontakt.module.css";
import { FaInfo, FaPhone } from "react-icons/fa";
import SEO from "../components/seo";
import Hero from "../components/hero";

class Kontakt extends Component {
  state = {
    sending: false
  };
  render() {
    return (
      <Layout>
        <SEO
          title="Demo CRM - Stwórz swój system za darmo"
          description="Demo • Mobilny program CRM online • Łatwy w obsłudze • Wypróbuj demo i przekonaj się, że prosty CRM może mieć wiele możliwości!"
        />
        <Hero maxHeight="280px" justifyContent="center">
          <h1>Prosty CRM</h1>
          <p>Korzystaj za darmo przez 30 dni</p>
        </Hero>
        <Container className={styles.wrapper}>
          <div className={styles.formWrapper}>
            <div className={styles.contactHeader}>
              <FaInfo size="50px" />
              <p>
              <strong>Napisz do nas, a prześlemy Ci dostęp do Twojego systemu!</strong>
              </p>
              <p>
                Pola oznaczone znakiem{" "}
                <span className={styles.required}>*</span> są wymagane
              </p>
            </div>
            <form
              method="POST"
              action="/form/contact.php"
              onSubmit={() => this.setState({ sending: true })}
              onError={() => this.setState({ sending: false })}
              className={styles.contactForm}
            >
              <label htmlFor="name">
                Imię i nazwisko <span className={styles.required}>*</span>
              </label>
              <input
                required
                type="text"
                name="name"
                value={this.state.name}
                onChange={e => this.handleInputChange(e)}
              />
              <label htmlFor="companyName">
                Nazwa firmy <span className={styles.required}>*</span>
              </label>
              <input required type="text" name="companyName" />
              <label htmlFor="companySize">Wielkość firmy</label>
              <select name="companySize" id="companySize">
                
                <option value="jednoosobowa">Działalność jednoosobowa</option>
                <option value="mikro">Mikro (do 10 pracowników)</option>
                <option value="mała">Mała (do 49 pracowników)</option>
                <option value="średnia">Średnia (do 249 pracowników)</option>
                <option value="duża">Duża (250 pracowników lub więcej)</option>
              </select>
              <label htmlFor="companyEmail">
                Adres e-mail <span className={styles.required}>*</span>
              </label>
              <input required type="text" name="companyEmail" />
              <label htmlFor="companyPhone">Numer telefonu</label>
              <input type="text" name="companyPhone" />
              <label htmlFor="message">Treść wiadomości</label>
              <textarea name="message" id="message" cols="30" rows="10" />
              <input
                required
                type="checkbox"
                name="allowProcessData"
                id={styles.allowProcessData}
              />
              <label
                htmlFor="allowProcessData"
                className={styles.processDataLabel}
              >
                Wyrażam zgodę na przetwarzanie danych osobowych.{" "}
                <span className={styles.required}>*</span>
              </label>
              <button
                style={{
                  marginTop: 20
                }}
                disabled={this.state.sending}
                type="submit"
              >
                {this.state.sending ? "Wysyłanie..." : "Wyślij"}
              </button>
            </form>
          </div>
          <div className={styles.mapContainer}>
            <div className={styles.contactHeader}>
              <FaPhone size="50px" />
              <p>
                <a
                  className={styles.phoneNum}
                  href="tel:+48506504540"
                  title="+48 506 504 540"
                >
                  +48 506 504 540
                </a>
              </p>
              <p>
                Pracujemy od poniedziałku do piątku <br />w godzinach 8 — 16
              </p>
            </div>
            <iframe
              title="Adres Questy"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2433.608052588435!2d16.913769930151982!3d52.413779407663064!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47045b4b2800c3e7%3A0x5336529b5602e3de!2sQuesty!5e0!3m2!1spl!2spl!4v1488363170109"
              // style="border: 0px none; pointer-events: none;"
              allowfullscreen=""
              width="400"
              height="300"
              frameborder="0"
            />
          </div>
        </Container>
      </Layout>
    );
  }
}

export default Kontakt;
